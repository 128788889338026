// 加密
import { JSEncrypt } from "jsencrypt";

export const encryptedData = function (data) {
  //公钥和私钥和后端沟通是固定的
  let publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDETd9nBVG00YQ8PcY1WYDIhseLAa7i7FK/1QPTXmLvut1GKeHje5M4ylnkpChjOyRStJy94MXowBt5Zi3O0V++wUknbl7aH9cdOhg7wGzBYG2n6j2aW2b+232/KTlmkwVvw+jaEeg3Or4J4+JTskuK+8Whch7JHBDiJ3Gw2T+6xwIDAQAB";
  // 新建JSEncrypt对象
  let encryptor = new JSEncrypt();
  // 设置公钥
  encryptor.setPublicKey(publicKey);
  // 加密数据
  return encryptor.encrypt(data);
};
